<template>
  <v-main>
  <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="mx-auto elevation-5 py-1">
              <v-img height="77" src="@/assets/logo.png" class="ma-5 pa-5" contain></v-img>
              <v-toolbar
                color="#1973ba"
                dark
                flat
              >
                <v-toolbar-title>Access</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="User"
                    name="user"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="datosLogin.user"
                  />

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="datosLogin.password"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="login" :loading="loading" :disabled="datosLogin.user == '' || datosLogin.password == ''">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
  </v-main>
</template>
<style scoped>
.v-application .primary {
    background-color: #1973ba !important;
    border-color: #1973ba !important;
}
</style>
<script>

export default {
    data: () => ({
        datosLogin: {
            user: '',
            password: '',
        },
        loading: false,
    }),
    methods: {
        login: function(){
            var este = this;
            var variables = {
              email: este.datosLogin.user,
              password: este.datosLogin.password,
            };
            este.loading = true;
            //process.env.NODE_ENV == 'production'
            este.axios.post(este.$url + 'login', variables).then(function(response){
                window.console.log(response);
                if(response.status == 200 && response.data.status == 'ok'){
                    console.log('IMPRIME DATOS');
                    console.log(response.data.data);
                    var datos = {
                        token: response.data.data[0].token,
                        id: response.data.data[0].id,
                        name: response.data.data[0].name,
                        last_name: response.data.data[0].last_name,
                        gender: response.data.data[0].gender,
                        birthday: response.data.data[0].birthday,
                        email: response.data.data[0].email,
                        phone: response.data.data[0].phone,
                        mobile: response.data.data[0].mobile,
                        street: response.data.data[0].street,
                        number: response.data.data[0].number,
                        city: response.data.data[0].city,
                        zip_code: response.data.data[0].zip_code,
                        state: response.data.data[0].state,
                        picture: response.data.data[0].picture,
                        updated_at: response.data.data[0].updated_at,
                    }
                    este.loading = false;
                    este.$store.commit('loginSend', datos);
                } else {
                    este.loading = false;
                    var mensaje = {
                        "color": "red",
                        "timeout": 5000,
                        "message": response.data.msg
                    }
                    este.$store.commit("msgMuestra", mensaje);
                }
            }).catch(function(err){
                este.loading = false;
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "Wrong user / password. Please try again."
                }
                este.$store.commit("msgMuestra", mensaje);
                window.console.log(err);
            });
        },
    },
    mounted: function(){
        if(this.$store.getters.login.token){
            this.$router.push('/');
        }
    }
}
</script>
